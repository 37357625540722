@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap");

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	font-family: var(--ff);
}

:root {
	--background: #ffffff;
	--backgroundAlpha: #ffffff;
	--primary: #3e74ff;
	--on-primary: #eceff6;
	--surface: #f5f5f5;
	--surface-shade: #8a8a8a;
	--surface-light: #d0d0d0;
	--text-primary: #121212;
	--text-secondary: #7a7a7a;
	--secondary: #d9e1f6;
	--ff: Manrope, arial;
	--border: #00000014;
	--on-secondary: #192136;
}

.dialog {
	padding: 24px;
	border: none;
	top: 10%;
	backdrop-filter: blur(30px);
	width: 400px;
	left: 50%;
	transform: translateX(-50%);
	position: relative;
}

.abs {
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
}

.container {
	max-width: 1240px;
	margin: auto;
	padding: 4px 4px;
	display: flex;
	align-items: center;
}

.longPassword {
	width: 400px;
}

.newLayout {
	max-width: 1240px;
	margin: auto;
	padding: 4px 4px;
}

.container:nth-child(even) {
	display: flex;
	flex-direction: row-reverse;
}

h1 {
	font-weight: 500;
	font-size: 4rem;
	letter-spacing: -1.92px;
	line-height: 1.1em;
}

.text {
	width: 620px;
	min-width: 343px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.topDown {
	display: grid;
	grid-template-rows: auto 1fr auto;
	min-height: 100vh;
}

.feature {
	display: flex;
}

.features {
	background-color: var(--surface);
}

p {
	line-height: 1.5em;
	color: var(--text-secondary);
}

nav {
	position: sticky;
	left: 0px;
	top: 0px;
	background-color: var(--backgroundAlpha);
	border-bottom: 1px solid var(--border);
	padding: 8px;
	/* -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px); */
}

.logo {
	font-weight: 700;
	font-size: 2rem;
	color: var(--primary);
}

.navigation {
	display: flex;
	justify-content: space-between;
}

.navigation ul {
	display: flex;
	gap: 2rem;
	list-style: none;
	cursor: pointer;
}

.navigation li:hover {
	color: var(--primary);
}

button {
	background-color: var(--primary);
	color: var(--on-primary);
	border: 1px solid var(--border);
	padding: 12px 24px;
	border-radius: 8px;
	transition: opacity ease-in 300ms;
	cursor: pointer;
}

button:hover {
	opacity: 0.8;
}

a {
	text-decoration: none;
	color: var(--text-secondary);
}

a:hover {
	color: var(--primary);
}

/* a:active{
  color: var(--primary);
} */

ul a:visited {
	color: var(--text-secondary);
}

ul a:visited:hover {
	color: var(--primary);
}

footer {
	background-color: var(--text-primary);
	color: var(--background);
	padding: 64px 16px;
}

.footer-item {
	flex: 1;
}

.footer-item li {
	list-style: none;
	font-size: 0.875rem;
	padding-left: 0;
}

.footer-item ul {
	padding-left: 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.footer-item ul a {
	color: var(--surface-light);
}

.footer-item ul a:hover {
	color: var(--background);
}

.container.footer {
	align-items: flex-start;
}

.footer h2 {
	margin-bottom: 16px;
}

.hero {
	background-image: url("./Images/Background.jpg");
	background-size: cover;
	height: 85vh;
}

.hero-download {
	background-image: url("./Images/Background-download.jpg");
	background-size: cover;
	height: 50vh;
}

.hero-about {
	background-image: url("./Images/Background-Rive.jpg");
	background-size: cover;
	height: 50vh;
}

.center {
	text-align: center;
}

.hero h1 {
	color: var(--background);
}

.hero-text {
	flex: 1;
}

.features img {
	width: 100%;
}

.form-wrapper {
	background-color: var(--background);
	padding: 30px;
	flex: 1;
	border-radius: 8px;
	border: 1px solid var(--border);
	box-shadow: 0px 4px 8px #00000014;
}
.form-wrapper button {
	width: 100%;
}

.contain {
	gap: 128px;
	height: 100%;
}

.secondary {
	background-color: var(--secondary);
	color: var(--primary);
	border: 1px solid var(--primary);
}

.hero-text p {
	color: var(--background);
}

.hero-text h1 {
	margin-bottom: 24px;
}

input,
select {
	font-family: inherit;
	padding: 12px 16px;
	border-radius: 8px;
	border: 1px solid var(--border);
	transition: border 300ms ease-out;
}

input:focus {
	outline: none;
	border: 1px solid var(--primary);
}

form {
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-size: inherit;
	font-family: inherit;
}

.titles {
	color: var(--background);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	height: 100%;
}

.titles p {
	color: var(--background);
}

.color {
	background-color: var(--secondary);
}

.mission {
	color: var(--primary);
}

.imager {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
}

.text-description {
	flex: 1;
	color: var(--on-secondary);
}

.text-description p {
	color: var(--on-secondary);
}

.text-description h1 {
	margin-bottom: 16px;
}

.heading {
	display: flex;
	gap: 16px;
	align-items: center;
	margin-bottom: 16px;
}

.heading h2 {
	font-size: 1.125rem;
	color: var(--text-primary);
}

.texting {
	color: var(--text-secondary);
}

.sets {
	flex-direction: column;
	padding: 48px 0px;
}

.sets h1 {
	margin-bottom: 24px;
}

.cover {
	display: flex;
	gap: 24px;
}

.feature-wrapper {
	border: 1px solid var(--border);
	padding: 24px;
	border-radius: 8px;
}

.contact-details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.contact-details h1 {
	margin: 24px 0px;
}

.contact-details h2 {
	margin: 8px 0px;
}

.contact-details P {
	width: 800px;
}

.contact-details ol {
	padding-bottom: 8px;
}

.contact-details ol li {
	margin: 24px 0;
}

.contact-details ul {
	color: var(--text-secondary);
	padding-bottom: 8px;
}

.contact-details ul li {
	margin: 8px 0px;
}

.down-wrapper {
	padding: 24px;
	background-color: var(--surface);
	display: flex;
	border: 1px solid var(--border);
	border-radius: 8px;
	align-items: center;
}

.downloaded {
	gap: 24px;
}

.down-wrapper h1 {
	font-size: 3rem;
	margin-bottom: 8px;
}

.ForDownload {
	margin: 32px 0;
}

.menu {
	background-color: var(--background);
	display: none;
}

.errorimg,
.errortxt {
	flex: 1;
}

.err {
	gap: 40px;
}

.btn {
	display: flex;
	gap: 24px;
}

.container.err {
	height: 68vh;
}

.question {
	border: 1px solid var(--border);
	border-radius: 8px;
	padding: 16px;
}

.questionwrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 70%;
	margin: 32px 0px;
}

.quest {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
}

.questioner {
	padding: 8px;
}

.vis {
	cursor: pointer;
}

.buttonwrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 24px;
	margin: 32px 0;
}

.cat {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.but {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	background-color: var(--surface);
	border: 1px solid var(--border);
	border-radius: 8px;
	cursor: pointer;
	padding: 56px;
	text-align: center;
}

.linksNavs li {
	list-style: none;
}

.container.reg {
	display: flex;
	flex-direction: column;
}

.outline {
	background-color: var(--background);
	align-self: flex-start;
}

.heads {
	margin-top: 2rem;
}

.formregwrap {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
}

.regwrapper {
	background-color: var(--surface);
	padding: 1.875rem;
	border: 1px solid var(--border);
	border-radius: 0.5rem;
	margin: 2rem 0px;
	width: 419px;
}

.otp-input {
	width: 100%;
}

.names {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.utility {
	width: 100%;
}

@media only screen and (max-width: 812px) {
	.container {
		flex-direction: column;
		height: 100%;
		padding: 16px;
		gap: 16px;
	}

	.longPassword {
		width: 100%;
	}

	.newLayout {
		width: 100%;

		padding: 16px;
	}

	.container:nth-child(even) {
		display: flex;
		flex-direction: column;
	}

	.form-wrapper {
		width: 100%;
	}

	.text {
		width: 100%;
	}

	.text h1,
	h1 {
		font-size: 32px;
		line-height: 3rem;
	}

	.hero {
		height: 800px;
	}

	.cover {
		flex-direction: column;
	}

	.titles p {
		text-align: center;
	}

	.imager {
		display: inline;
		width: 100%;
	}

	.contact-details P {
		width: 100%;
	}

	.contact-details h2 {
		font-size: 24px;
		font-weight: 600;
	}

	.down-wrapper {
		flex-direction: column;
	}

	.down-wrapper h1 {
		font-size: 1.5rem;
		margin-bottom: 8px;
		line-height: 1.7rem;
	}

	.menu {
		display: inline;
	}

	.linksNav {
		display: none;
	}

	.navigation ul {
		display: none;
	}

	.container.navigation {
		display: flex;
		flex-direction: row;
		padding: 8px 16px;
	}

	nav {
		padding: 0px;
	}

	body {
		max-width: 100%;
	}

	.err button {
		width: 100%;
	}

	.btn {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.err {
		flex-direction: column-reverse;
	}

	.container.err {
		height: 100vh;
	}

	.questionwrapper {
		width: 100%;
	}

	.buttonwrapper {
		overflow-x: scroll;
	}

	.but {
		padding: 32px;
		flex: 1;
	}

	.cat img {
		width: 32px;
	}

	.regwrapper {
		width: 100%;
	}

	/* .forMobile {
		position: sticky;
		flex-direction: row;
		left: 0;
		right: 0;
		bottom: 2px;
	} */
	.navtit {
		display: flex;
		flex-direction: column;
		font-size: 11px;
		align-items: center;
	}

	.sidebar {
		flex-direction: row;
		position: absolute;
		height: 64px;
		bottom: 0px;
		width: 100vw;
		border-top: 1px solid #12121215;
	}

	.sidebar-link {
		padding: 8px 0;
	}

	.sidebar-link.active {
		border-right: none;
		border-top: 3px solid #121212;
	}
	.sidebar-link:hover {
		border-right: none;
		border-top: 3px solid #121212;
	}

	.sidebar > * {
		flex: 1;
	}

	.content {
		padding: 0 16px;
		width: 100%;
	}

	.ments {
		width: 100%;
	}

	.topNav {
		flex-direction: column;
	}

	.logos {
		width: 100%;
		justify-content: flex-start;
		padding: 16px;
		border-bottom: 1px solid var(--border);
	}

	.leftSide {
		padding: 8px 16px;
	}

	.hide {
		display: none;
	}

	table {
		width: 100%;
		overflow-x: scroll;
	}

	.brox {
		width: 100%;
		height: 300px;
		overflow-x: auto;
		overflow-y: auto;
	}
}

/* progress bar */
.progress-bar-container {
	max-width: 1240px;
	margin: 1.5rem auto;
	padding: 2rem;
	border: 1px solid var(--border);
	border-radius: 0.5rem;
}

.daa {
	text-align: right;
}

.progress-bar {
	height: 1rem;
	background-color: #ddd;
}

.step-names {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
	color: var(--surface-shade);
}

.step-name {
	flex: 1;
	text-align: center;
	padding: 0.5rem;
	cursor: pointer;
}

.progressActive {
	/* Your active style for the active step */
	background-color: var(--primary);
	color: #fff;
	border-radius: 0.5rem;
}

.verificationStatus {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.datapreview {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

/* just for settings */
.editable-field {
	cursor: pointer;
}

/* popMenu */
.popupMenu {
	display: flex;
	flex-direction: column;
	border: 1px solid var(--border);
	gap: 0.5rem;
	padding: 1rem;
	background-color: var(--background);
	border-radius: 0.5rem;
	position: absolute;
}

td > div.overlay {
	position: relative;
}

.popupMenu > a {
	padding: 0.5rem;
	color: var(--text-secondary);
}

.popupMenu > div {
	padding: 0.5rem;
	color: var(--text-secondary);
}

.popupMenu > div:hover {
	background-color: #f6d9d9;
	border-radius: 0.5rem;
	color: #ff3e3e;
}

.popupMenu > a:hover {
	background-color: var(--surface);
	border-radius: 0.5rem;
	color: var(--text-primary);
}

/* info */
.info {
	display: flex;
	padding: 1rem;
	background-color: var(--surface);
	border-left: 5px solid var(--text-primary);
}

.info-blue {
	display: flex;
	padding: 1rem;
	background-color: #d9e8f6;
	color: #192836;
	border-left: 5px solid var(--primary);
}

.docspace {
	margin-bottom: 1rem;
}

.RedadminSearch {
	background-color: #ff3e3e;
}
