:root {
	--background: #ffffff;
	--backgroundAlpha: #ffffff;
	--primary: #3e74ff;
	--on-primary: #eceff6;
	--surface: #f5f5f5;
	--surface-shade: #8a8a8a;
	--surface-light: #d0d0d0;
	--text-primary: #121212;
	--text-secondary: #7a7a7a;
	--secondary: #d9e1f6;
	--ff: Manrope, arial;
	--border: #00000014;
	--on-secondary: #192136;
}

.logos {
	display: flex;
	align-items: center;
	font-size: 1.25rem;
	gap: 0.5rem;
	justify-content: center;
	width: 15rem;
	padding: 1.5rem 0;
}

.topNav {
	display: flex;
	border-bottom: 1px solid var(--border);
}

.leftSide {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-left: 1px solid var(--border);
	padding: 0 1.88rem;
	flex: 1;
}

.namesHead {
	display: flex;
	flex-direction: column;
	gap: 0;
}

.namesHead h1 {
	font-size: 1.5rem;
}

.namesHead p {
	font-size: 0.75rem;
}

.headsearch {
	border: 0;
	margin: 0;
	padding: 0;
	outline: 0;
}

.headsearch:focus {
	border: 0;
	outline: 0;
}

.inputsearch {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	padding: 0.63rem 1rem;
	border: 1px solid var(--border);
	border-radius: 0.5rem;
	width: 100%;
}

.adminSearch {
	background-color: var(--text-primary);
}

.sided {
	display: flex;
	gap: 0.5rem;
	width: 40%;
}

/* content starts from here */
/* Sidebar.css */

.sidebar {
	width: calc(15rem + 1px);
	border-right: 1px solid var(--border);
	height: calc(100vh - 89px);
	display: flex;
	flex-direction: column;
}

.sidebar-link {
	display: block;
	padding: 1rem 2rem;
	color: var(--surface-shade);
	text-decoration: none;
}

.sidebar-link:hover {
	background-color: #f5f5f5;
	border-right: 3px solid #121212;
	color: var(--text-primary);
}

.sidebar-link.active {
	background-color: #f5f5f5;
	border-right: 3px solid #121212;
	font-weight: bold;
	color: #121212;
}

.icon {
	margin-right: 8px;
	stroke: var(--surface-shade);
	stroke-width: 2;
}

.sidebar-link.active .icon {
	stroke: #121212;
}

.app {
	display: flex;
}

.content {
	flex: 1;
}

.contentout {
	max-width: 71.25rem;
	margin: auto;
	margin-top: 1.69rem;
}

.navtit {
	display: flex;
	align-items: center;
}

/* New Codes */
table {
	border: 1px solid var(--border);
	width: 100%;
	border-collapse: collapse;
	/* Ensure that borders collapse into a single border */
}

thead {
	background-color: var(--surface);
}

th,
td {
	border: 1px solid var(--border);
	/* Apply border to both th and td */
	padding: 8px;
	text-align: left;
}

.sortings {
	display: flex;
	/* width: 100%; */
	justify-content: space-between;
	align-items: center;
}

.adminSearchIcon {
	cursor: pointer;
	margin-left: 4px;
	/* Adjust the margin as needed for spacing */
}

.adminclick {
	cursor: pointer;
}

.ment {
	width: 30%;
}

.ments {
	width: 600px;
	margin-top: 16px;
}

/* Login */
.adminLogin {
	height: 100vh;
	background-color: var(--surface);
	display: flex;
	align-items: center;
	justify-content: center;
}

.loginCenter {
	background-color: var(--background);
	border: 1px solid var(--border);
	padding: 3.12rem;
	border-radius: 0.5rem;
}

.loginCenter h1 {
	margin-bottom: 1rem;
	font-size: 3rem;
	min-width: 21.4375rem;
}

.loginCenter input[type="text"]:focus,
input[type="password"]:focus {
	border-color: var(--text-primary);
}

.secondarySearcher {
	background-color: var(--background);
	color: var(--surface-shade);
	border: 1px solid var(--border);
}

.paginations {
	margin-top: 2rem;
	display: flex;
	gap: 0.5rem;
}

/* for icon */
.actionButton {
	background: none;
	color: var(--text-secondary);
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
}

.actionButton:hover {
	color: var(--text-primary);
	/* Change the color on hover if needed */
}
